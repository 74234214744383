const API =
  process.env.REACT_APP_STAGE === 'prod'
    ? {
        URL: 'https://dev-sms.tadoo.biz',
        REGION: 'eu-west-3'
      }
    : { URL: 'https://sms.tadoo.biz', REGION: 'eu-west-3' };

const COGNITO =
  process.env.REACT_APP_STAGE === 'prod'
    ? {
        COGNITO_REGION: 'eu-central-1',
        USER_POOL_ID: 'eu-central-1_ZPcaI0dIX',
        APP_CLIENT_ID: '7h7qql9umc0t1rd5pi80ehvk7u',
        IDENTITY_POOL_ID: 'eu-central-1:9dda979a-bb8b-4ede-9328-da03f69e7b81'
      }
    : {
        COGNITO_REGION: 'eu-central-1',
        USER_POOL_ID: 'eu-central-1_YijQEVIx1',
        APP_CLIENT_ID: '2tovm3k4iqlj03so7cv5g3l59',
        IDENTITY_POOL_ID: 'eu-central-1:84ecbaeb-cc66-4b06-a51d-27d3cfd2b54e'
      };

const ERRORS = {
  PASSWORD_TOO_SHORT_MIN_5: 'Password must have at least 5 characters',
  EMAIL_ALREADY_EXISTS: 'The email is already registered',
  UNKNOWN_ERROR: 'Something went wrong, please contact the admin',
  USER_DOES_NOT_EXIST: "The users doesn't exist"
};

const DEFAULT_USERS = [
  { id: 67, name: 'DefaultSIM', routing: 'TBL_SIMrouting', type: 'SIM' },
  { id: 68, name: 'DefaultLabs', routing: 'TBL_TadooHQ', type: 'HQ' },
  { id: 80, name: 'DefaultSS7', routing: 'TBL_SS7_DEFAULT', type: 'SS7' },
  {
    id: 237,
    name: 'DefaultLD',
    routing: 'TBL_DIRECTLOCAL_DEFAULT',
    type: 'LC'
  },
  {
    id: 397,
    name: 'DefaultVOTP',
    routing: 'TBL_DEFAULT_VOTP',
    type: 'VOTP'
  },
  {
    id: 432,
    name: 'DefaultOwnSS7',
    routing: 'TBL_OWNSS7_Default',
    type: 'SS7'
  }
];

const HLR_ERRORS = {
  '001': 'Invalid Query Format',
  '002': 'Query Service Invalid',
  '003': 'Service Not Allowed',
  '004': 'Country Code Invalid',
  '005': 'Wrong Number Length or Format',
  '006': 'Range Not Allocated',
  '007': 'Number Not Found - Onboard',
  '008': 'Number Not Found - Remote',
  '009': 'Service Error',
  '010': 'Remote Timeout',
  '012': 'Customer Not Authorised',
  '013': 'Rate Limit Reached',
  100: 'Subscriber Error (Temporary)',
  101: 'Unknown Subscriber',
  102: 'Absent Subscriber',
  103: 'Telephone Service not provisioned',
  104: 'Roaming Not Allowed',
  105: 'Subscriber Busy',
  106: 'Subscriber Blocked',
  107: 'Equipment Error',
  108: 'Phone Switched Off',
  109: 'Destination Network Error',
  110: 'Facility Not Supported',
  111: 'Destination Network Busy',
  112: 'No Network Response',
  113: 'Destination Error',
  114: 'Destination Network Unavailable.',
  115: 'Service Not Supported',
  116: 'Number Not Found - UK Port History',
  120: 'Internal System Error',
  141: 'Remote Error - GNR Returned',
  142: 'Token Not Found',
  143: 'Token Expired',
  200: 'Remote query failure - GNR returned',
  300: 'Absent Subscriber',
  301: 'Unknown Subscriber',
  302: 'Live Status Unavailable',
  303: 'Subscriber Error',
  304: 'Destination/Network Error',
  305: 'Call Barred',
  306: 'Teles',
  307: 'Facility Not Supported'
};

export { API, ERRORS, COGNITO, DEFAULT_USERS, HLR_ERRORS };
