import { API, Auth } from 'aws-amplify';
import PQueue from 'p-queue';
import notificationUI from '../../libs/notificationUI';
import messageUI from '../../libs/messageUI';
import base from './base.json';
import baseMCCMNC from './baseMCCMNC.json';

const getToken = async () => {
  const auth = await Auth.currentAuthenticatedUser();
  return auth.signInUserSession.idToken.jwtToken;
};

const updateUsers = async params => {
  const token = await getToken();
  try {
    const req = await API.get('sms-api', '/update-users-pricelist', {
      headers: {
        Authorization: token
      }
    });
    return req;
  } catch (err) {
    console.log(err.response);
  }
};

const saveToMcore = async params => {
  const notiUIdata = {
    action: 'saving',
    what: 'data in mcore'
  };
  notificationUI({
    state: 'info',
    duration: 3,
    ...notiUIdata
  });
  const token = await getToken();
  try {
    await API.post('sms-api', '/save-users-pricelists', {
      headers: {
        Authorization: token
      },
      body: params
    });
    notificationUI({
      state: 'success',
      ...notiUIdata
    });
    notificationUI({
      state: 'info',
      duration: 3,
      action: 'syncing',
      what: 'data in tadoo db'
    });
  } catch (err) {
    console.log(err.response);
    notificationUI({
      state: 'error',
      ...notiUIdata
    });
  }
};

const sendEmailServiceOptions = {
  dev: async data => {
    const notiUIdata = {
      action: 'sending',
      what: 'email'
    };
    notificationUI({
      state: 'info',
      duration: 3,
      ...notiUIdata
    });
    notificationUI({
      state: 'success',
      key: 'email',
      ...notiUIdata
    });
    console.log('DEV: sendEmailService');
    console.log(data);
  },
  prod: async data => {
    const notiUIdata = {
      action: 'sending',
      what: 'email'
    };
    notificationUI({
      state: 'info',
      duration: 3,
      ...notiUIdata
    });
    const token = await getToken();
    try {
      await API.post('sms-api', `/send-email`, {
        headers: {
          Authorization: token
        },
        body: data
      });
      notificationUI({
        state: 'success',
        key: 'email',
        ...notiUIdata
      });
    } catch (err) {
      console.log(err);
      notificationUI({
        state: 'error',
        ...notiUIdata
      });
    }
  }
};
const sendEmailService = sendEmailServiceOptions[process.env.REACT_APP_STAGE];

const getUsers = async ({ _token } = {}) => {
  const msgUIname = 'users';
  messageUI({ state: 'loading', name: msgUIname });
  const token = _token || (await getToken());
  try {
    const users = await API.get('sms-api', '/get-users', {
      headers: {
        Authorization: token
      }
    });
    messageUI({ state: 'success', name: msgUIname });
    return users;
  } catch (err) {
    console.error(err);
    messageUI({ state: 'error', name: msgUIname });
  }
};

const getRouting = async ({ _token } = {}) => {
  const token = _token || (await getToken());
  try {
    const routing = await API.get('sms-api', '/get-routing', {
      headers: {
        Authorization: token
      }
    });
    return routing;
  } catch (err) {
    console.error(err);
  }
};

// const _getRouting = async ({ _token } = {}) => {
//   const msgUIname = 'routing';
//   messageUI({ state: 'loading', name: msgUIname });
//   const token = _token || (await getToken());
//   const routingTrans = {};
//   try {
//     const routing = await API.get('sms-api', '/get-routing', {
//       headers: {
//         Authorization: token
//       }
//     });
//     routing.forEach(t => {
//       routingTrans[t.tableName] = t.rules.map(r => ({
//         destination: r.destination.replace('smppclient.', ''),
//         cc: r.value
//       }));
//     });
//     const usersRouting = routing
//       .find(t => t.tableName === 'MESSAGE')
//       .rules.map(r => ({
//         id: r.value,
//         routing: r.destination.replace('smppclient.', '')
//       }));
//     messageUI({ state: 'success', name: msgUIname });
//     return { usersRouting: usersRouting, routingTrans: routingTrans };
//   } catch (err) {
//     console.error(err);
//     messageUI({ state: 'error', name: msgUIname });
//   }
// };

const getVendors = async ({ _token } = {}) => {
  const msgUIname = 'vendors';
  messageUI({ state: 'loading', name: msgUIname });
  const token = _token || (await getToken());
  try {
    const rawVendors = await API.get('sms-api', '/get-vendors', {
      headers: {
        Authorization: token
      }
    });
    messageUI({ state: 'success', name: msgUIname });
    return rawVendors;
  } catch (err) {
    console.error(err);
    messageUI({ state: 'error', name: msgUIname });
  }
};

const getPricelist = async _filters => {
  const msgUIname = 'pricelist';
  const hiddeMsg = _filters?.hiddeMsg;
  delete _filters.hiddeMsg;
  if (!hiddeMsg) {
    messageUI({ state: 'loading', name: msgUIname });
  }
  const headers = {
    headers: {
      Authorization: await getToken()
    }
  };
  let filters = _filters;
  let r;
  let routes = [];
  let pUserId;
  let pMccmnc;
  try {
    do {
      pUserId = r?.meta?.pagination?.userId;
      pMccmnc = r?.meta?.pagination?.mccmnc;
      if (pUserId && pMccmnc) {
        filters = {
          ...filters,
          pagination: `${pUserId},${pMccmnc}`
        };
      }
      r = await API.get(
        'sms-api',
        `/get-pricelist/?${new URLSearchParams(filters).toString()}`,
        headers
      );
      routes = [...routes, ...r.data];
    } while (r.meta.pagination?.userId && r.meta.pagination?.mccmnc);
    // userIds as keys
    const usersRoutes = {};
    routes.forEach(r => {
      if (!(r.userId in usersRoutes)) {
        usersRoutes[r.userId] = [];
      }
      usersRoutes[r.userId].push(r);
    });
    if (!hiddeMsg) {
      messageUI({ state: 'success', name: 'everything has been' });
    }
    return usersRoutes;
  } catch (err) {
    console.log(err);
    messageUI({ state: 'error', name: msgUIname });
  }
};

const getUsersPricelist = async ({
  _users,
  _usersRouting,
  _rawVendors,
  _routingTrans,
  _token
}) => {
  const msgUIname = 'pricelist';
  messageUI({ state: 'loading', name: msgUIname });
  const token = _token || (await getToken());
  const queue = new PQueue({
    concurrency: 1,
    timeout: 15 * 1000
  });
  const users = _users.map((u, idx) => {
    const routing = _usersRouting.find(({ id }) => id === u.id.toString());
    if (routing) {
      u.routing = routing.routing.replace('smppclient.', '');
    }
    return u;
  });

  let usersPricelistReq = users.map(
    user => () =>
      API.get('sms-api', `/get-user-pricelist-new/${user.id}`, {
        headers: {
          Authorization: token
        }
      })
  );
  try {
    usersPricelistReq = await queue.addAll(usersPricelistReq);
    queue.clear();
    const pricesWithUsersKeys = {};
    usersPricelistReq.forEach((u, idx) => {
      pricesWithUsersKeys[users[idx].id] = u;
    });
    const keys = Object.keys(pricesWithUsersKeys);
    keys.forEach((key, idx) => {
      const user = pricesWithUsersKeys[key];
      const userRouting = users.find(({ id }) => id.toString() === key);
      if (userRouting?.routing && _routingTrans[userRouting.routing]) {
        user.key = key;
        // Let's map user pricelist to discover the routing configuration per
        // each route
        // Eg:
        // route: {"mccmnc":"45205","price":0.03,"network":"Vietnamobile (Hanoi Telecom)","country":"Vietnam","currency":"EUR"}
        // found: {"MCC":"452","MNC":"05","ISO":"vn","Country":"Vietnam","Country Code":"84","Network":"VietnaMobile "}
        // routing: {"destination":"itdecisionDIR","cc":"84"}
        user.map(route => {
          // Base file contains the CC need it to get the Routing config (vendor)
          // If a route is not within the vendor, default price is applied
          let found;
          // By the route mccmnc we try to get the cc
          // If not, we try it by its country name
          if (route.mccmnc in baseMCCMNC) {
            found = baseMCCMNC[route.mccmnc];
          } else {
            found = base.find(
              ({ Country }) =>
                Country === route.country ||
                Country.split(' ')[0] === route.country ||
                Country === route.country.split(' ')[0]
            );
          }
          if (found) {
            let routing = _routingTrans[userRouting.routing].find(
              rule => rule.cc === found['Country Code']
            );
            let findNextTable;
            if (!routing) {
              findNextTable = _routingTrans[userRouting.routing].find(
                rule => rule.cc === ''
              );
              if (
                findNextTable?.destination &&
                !findNextTable.destination.includes('filter')
              ) {
                routing = _routingTrans[findNextTable.destination].find(
                  rule => rule.cc === found['Country Code']
                );
              }
            }
            const destination = routing?.destination || '';
            if (
              (destination && userRouting) ||
              (destination && findNextTable)
            ) {
              route.table = findNextTable?.destination || userRouting.routing;
            }

            const vendor = _rawVendors.filter(r => r.vendor === destination);
            if (vendor) {
              const vendorData = vendor.filter(
                v => v.mccmnc.slice(0, 3) === found.MCC
              );
              if (vendorData) {
                const vendorNetwork = vendorData.find(
                  r => route.mccmnc === r.mccmnc
                );
                if (vendorNetwork) {
                  route.vendor = vendorNetwork.vendor;
                  route.cost = vendorNetwork.price;
                } else {
                  const vendorDefault = vendorData.find(
                    r => `${found.MCC}0` === r.mccmnc
                  );
                  if (vendorDefault) {
                    route.vendor = vendorDefault.vendor;
                    route.cost = vendorDefault.price;
                  }
                }
              }
            }
          }
          route.user = key;
          return route;
        });
      }
      pricesWithUsersKeys[key] = user;
    });
    users.map((u, idx) => {
      u.key = keys[idx];
      return u;
    });
    messageUI({ state: 'success', name: 'everything has been' });
    return { users: users, pricelist: pricesWithUsersKeys };
  } catch (err) {
    console.log(err);
    messageUI({ state: 'error', name: msgUIname });
  }
};

const checkUsersExceptions = async (exceptions, _token) => {
  const token = _token || (await getToken());
  try {
    return await API.post('sms-api', '/check-users-exceptions', {
      headers: {
        Authorization: token
      },
      body: exceptions
    });
  } catch (err) {
    console.log(err.response);
  }
};

const checkUsersDifferences = async (userId, _token) => {
  const token = _token || (await getToken());
  try {
    return await API.get('sms-api', `/get-default-differences/${userId}`, {
      headers: {
        Authorization: token
      }
    });
  } catch (err) {
    console.log(err.response);
  }
};

const getHistory = async _token => {
  const token = _token || (await getToken());
  try {
    await API.get('sms-api', '/get-config/history', {
      headers: {
        Authorization: token
      }
    });
  } catch (err) {
    console.log(err.response);
  }
};

export {
  getToken,
  updateUsers,
  saveToMcore,
  sendEmailService,
  getUsers,
  getRouting,
  getVendors,
  getPricelist,
  getUsersPricelist,
  checkUsersExceptions,
  getHistory,
  checkUsersDifferences
};
